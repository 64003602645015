var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue-credit-card" }, [
    _c("div", { staticClass: "card-form-and-image flex-col md:flex-row" }, [
      _c("div", { staticClass: "credit-card-form" }, [
        _c(
          "div",
          { staticClass: "field", class: { error: _vm.errorHolderName } },
          [
            _c("label", { attrs: { for: "name" } }, [
              _vm._v(_vm._s(_vm.$t("form.cardholder"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name",
                },
              ],
              ref: "holderName",
              staticClass: "nex-input",
              attrs: {
                maxlength: "26",
                name: "name",
                id: "name",
                autofocus: "",
                type: "text",
                placeholder: _vm.$t("form.cardholder"),
                "data-cy": "creditcard_form-holder_name-input",
              },
              domProps: { value: _vm.form.name },
              on: {
                click: function ($event) {
                  return _vm.eventGtm("custom.creditCard-HolderName")
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "name", $event.target.value)
                },
              },
            }),
            _c("small", [_vm._v(_vm._s(_vm.errorMessages.holder_name))]),
          ]
        ),
        _c(
          "div",
          { staticClass: "field", class: { error: _vm.errorCardNumber } },
          [
            _c("label", { attrs: { for: "card-number" } }, [
              _vm._v(_vm._s(_vm.$t("form.card_number"))),
            ]),
            _c("input", {
              ref: "cardNumber",
              staticClass: "nex-input",
              attrs: {
                type: "text",
                name: "card_number",
                id: "card-number",
                pattern: "[0-9 ]*",
                inputmode: "numeric",
                placeholder: _vm.$t("form.card_number"),
                "data-cy": "creditcard_form-card_number-input",
              },
              on: {
                click: function ($event) {
                  return _vm.eventGtm("custom.creditCard-CardNumber")
                },
              },
            }),
            _c(
              "svg",
              {
                staticClass: "ccicon",
                attrs: {
                  width: "750",
                  height: "471",
                  viewBox: "0 0 750 471",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                },
              },
              [_c(_vm.cardIcon, { tag: "component" })],
              1
            ),
            _c("small", [_vm._v(_vm._s(_vm.errorMessages.card_number))]),
          ]
        ),
        _c("div", { staticClass: "field-group" }, [
          _c(
            "div",
            { staticClass: "field", class: { error: _vm.errorCardExpiration } },
            [
              _c("label", { attrs: { for: "expirationdate" } }, [
                _vm._v(_vm._s(_vm.$t("form.validity"))),
              ]),
              _c("input", {
                ref: "expiration",
                staticClass: "nex-input",
                attrs: {
                  type: "text",
                  name: "expiration_date",
                  id: "expirationdate",
                  pattern: "[0-9\\/]*",
                  placeholder: _vm.trans.expiration.placeholder,
                  inputmode: "numeric",
                  "data-cy": "creditcard_form-expiration_date-input",
                },
                on: {
                  click: function ($event) {
                    return _vm.eventGtm("custom.creditCard-ExpirationDate")
                  },
                },
              }),
              _c("small", [_vm._v(_vm._s(_vm.errorMessages.card_expiration))]),
            ]
          ),
          _c(
            "div",
            { staticClass: "field", class: { error: _vm.errorCardCvv } },
            [
              _c("label", { attrs: { for: "securitycode" } }, [
                _vm._v(_vm._s(_vm.$t("form.security_code"))),
              ]),
              _c("input", {
                ref: "security",
                staticClass: "nex-input",
                attrs: {
                  type: "text",
                  name: "security_code",
                  id: "securitycode",
                  pattern: "[0-9]*",
                  placeholder: _vm.trans.security.placeholder,
                  inputmode: "numeric",
                  "data-cy": "creditcard_form-security_code-input",
                },
                on: {
                  click: function ($event) {
                    return _vm.eventGtm("custom.creditCard-SecurityCode")
                  },
                },
              }),
              _c("small", [_vm._v(_vm._s(_vm.errorMessages.card_cvv))]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }