<template>
  <div class="vue-credit-card">
    <div class="card-form-and-image flex-col md:flex-row">
      <div class="credit-card-form">
        <div class="field" :class="{ error: errorHolderName }">
          <label for="name">{{ $t('form.cardholder') }}</label>
          <input
            maxlength="26"
            class="nex-input"
            name="name"
            id="name"
            ref="holderName"
            autofocus
            type="text"
            :placeholder="$t('form.cardholder')"
            v-model="form.name"
            data-cy="creditcard_form-holder_name-input"
            @click="eventGtm('custom.creditCard-HolderName')"
          />
          <small>{{ errorMessages.holder_name }}</small>
        </div>
        <div class="field" :class="{ error: errorCardNumber }">
          <label for="card-number">{{ $t('form.card_number') }}</label>
          <input
            type="text"
            class="nex-input"
            name="card_number"
            id="card-number"
            ref="cardNumber"
            pattern="[0-9 ]*"
            inputmode="numeric"
            :placeholder="$t('form.card_number')"
            data-cy="creditcard_form-card_number-input"
            @click="eventGtm('custom.creditCard-CardNumber')"
          />
          <svg
            class="ccicon"
            width="750"
            height="471"
            viewBox="0 0 750 471"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <component :is="cardIcon" />
          </svg>
          <small>{{ errorMessages.card_number }}</small>
        </div>
        <div class="field-group">
          <div class="field" :class="{ error: errorCardExpiration }">
            <label for="expirationdate">{{ $t('form.validity') }}</label>
            <input
              type="text"
              class="nex-input"
              name="expiration_date"
              id="expirationdate"
              pattern="[0-9\/]*"
              ref="expiration"
              :placeholder="trans.expiration.placeholder"
              inputmode="numeric"
              data-cy="creditcard_form-expiration_date-input"
              @click="eventGtm('custom.creditCard-ExpirationDate')"
            />
            <small>{{ errorMessages.card_expiration }}</small>
          </div>
          <div class="field" :class="{ error: errorCardCvv }">
            <label for="securitycode">{{ $t('form.security_code') }}</label>
            <input
              type="text"
              class="nex-input"
              name="security_code"
              id="securitycode"
              ref="security"
              pattern="[0-9]*"
              :placeholder="trans.security.placeholder"
              inputmode="numeric"
              data-cy="creditcard_form-security_code-input"
              @click="eventGtm('custom.creditCard-SecurityCode')"
            />
            <small>{{ errorMessages.card_cvv }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IMask from "imask";
import cardTypes from "./cardTypes";
import { cardMasks, expirationMask, securityMask } from "./masks";
import * as InputIcons from "./components/icons";
import * as CardIcons from "./components/singles";
import {
  CHECKOUT_MODULE,
  GET_CREDIT_CARD_TOKEN_ERRORS,
} from "@/store/constants/checkout";

const formDefaults = {
  name: "",
  cardNumber: "",
  expiration: "",
  security: "",
};

const defaultColor = "grey";

const defaultTranslations = {
  name: {
    label: "Nome do Titular",
    placeholder: "Nome do Titular",
  },
  card: {
    label: "Número do Cartão",
    placeholder: "Número do Cartão",
  },
  expiration: {
    label: "Validade",
    placeholder: "MM/AA",
  },
  security: {
    label: "Cód. de Segurança",
    placeholder: "CVV",
  },
};

const ERROR_MESSAGES = {
  holder_name: null,
  card_number: null,
  card_expiration: null,
  card_cvv: null,
};

export default {
  mounted() {
    this.defineMasks();
    this.setMasksListeners();
    this.errorMessages = { ...ERROR_MESSAGES };
  },
  data() {
    return {
      cardType: null,
      cardIcon: null,
      cardInnerIcon: null,
      color: "grey",
      form: formDefaults,
      trans: defaultTranslations,
      errorMessages: ERROR_MESSAGES,
      creditCardLabels: {
        holder_name: this.$t('form.cardholder'),
        card_expiration: this.$t('form.validity'),
        card_number: this.$t('form.card_number'),
        card_cvv: this.$t('form.security_code'),
        payment_company_code: "Cartão",
      },
    };
  },
  methods: {
    validateField(field) {
      this.errorMessages[field] = "";
      if (
        this.creditCardTokenErrors?.error &&
        this.creditCardTokenErrors?.errorCode === "payment" &&
        this.creditCardTokenErrors?.data.some(
          (item) => item.parameter === field
        )
      ) {
        const error = this.creditCardTokenErrors.data.find(
          (item) => item.parameter === field
        );
        this.errorMessages[
          field
        ] = `${this.creditCardLabels[field]} ${error?.message}`;
        return true;
      }
      return false;
    },

    defineMasks() {
      //Mask the Credit Card Number Input
      this.cardNumberMask = new IMask(this.$refs.cardNumber, cardMasks);

      //Mask the Expiration Date
      this.expirationDateMask = new IMask(
        this.$refs.expiration,
        expirationMask
      );

      //Mask the security code
      this.securityCodeMask = new IMask(this.$refs.security, securityMask);
    },
    setMasksListeners() {
      // Update expiration date field
      this.expirationDateMask.on(
        "accept",
        () => (this.form.expiration = this.expirationDateMask.value)
      );

      // Update security code field
      this.securityCodeMask.on(
        "accept",
        () => (this.form.security = this.securityCodeMask.value)
      );

      // Update card number field and card icon
      this.cardNumberMask.on("accept", () => {
        const cardName = this.cardNumberMask.masked.currentMask.cardtype;
        this.form.cardNumber = this.cardNumberMask.value;
        if (cardTypes.hasOwnProperty(cardName)) {
          const card = cardTypes[cardName];

          this.cardIcon = InputIcons[card.name];
          this.cardInnerIcon = CardIcons[card.name];
          this.cardType = card.name;
          this.setColor(card.color);
          return;
        }

        this.resetCardDefaults();
      });
    },
    resetCardDefaults() {
      this.cardIcon = null;
      this.cardInnerIcon = null;
      this.cardType = null;
      this.setColor();
    },
    setColor(colorName) {
      this.color = colorName || defaultColor;
    },
  },
  computed: {
    ...mapGetters({
      creditCardTokenErrors: `${CHECKOUT_MODULE}/${GET_CREDIT_CARD_TOKEN_ERRORS}`,
      eventGtm: 'eventGtm'
    }),
    fields() {
      return [
        this.form.name,
        this.form.cardNumber,
        this.form.expiration,
        this.form.security,
      ].join(" ");
    },
    errorHolderName() {
      return this.validateField("holder_name");
    },
    errorCardNumber() {
      return this.validateField("card_number");
    },
    errorCardExpiration() {
      return this.validateField("card_expiration");
    },
    errorCardCvv() {
      return this.validateField("card_cvv");
    },
  },
  watch: {
    form() {},
    creditCardTokenErrors: function () {
      this.errorMessages = ERROR_MESSAGES;
      const data = this.creditCardTokenErrors;
      if (data.error && data.errorCode === "payment") {
        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index];
          this.errorMessages[element.parameter] = element.message;
        }
      }
    },
    fields() {
      this.$emit("change", Object.assign({}, this.$data.form));
    },
    cardType(val) {
      this.$emit("cardChanged", val);
    },
  },
};
</script>

<style lang="scss">
.vue-credit-card {
  display: flex;
  text-align: left;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  .card-form-and-image {
    display: flex;
    align-items: center;
    justify-content: center;

    .credit-card-form {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      max-width: 450px;
      padding: 20px;
      color: #707070;

      .field-group {
        display: flex;

        .field:first-child {
          margin-right: 10px;
        }
      }

      .field {
        position: relative;
        width: 100%;
        margin: 0;
        @apply mb-2;
        label {
          font-size: 14px;
          color: #818181;
          @apply block;
        }
        small {
          @apply font-light block text-right h-5 block;
        }
        input {
          @apply font-extralight;
        }
        &.error {
          label {
            @apply text-red-700;
          }
          input {
            @apply border-red-600 text-red-700 bg-red-50;
          }
          small {
            @apply text-red-700;
          }
        }
      }
    }
  }
  .ccicon {
    height: 38px;
    position: absolute;
    right: 6px;
    top: calc(50% - 21px);
    width: 60px;
    border-radius: 3px;
  }
}

@screen sm {
  .vue-credit-card {
    .card-form-and-image {
      .credit-card-form {
        .field-group {
          .field:first-child {
          }
        }
        .field {
          label {
          }
          small {
          }
          input {
          }
          &.error {
            label {
            }
            input {
            }
            small {
            }
          }
        }
      }
    }
    .ccicon {
      top: calc(50% - 20px);
    }
  }
}
</style>
