import IMask from 'imask';

export const cardMasks = {
  mask: [
    {
      mask: '0000 0000 0000 0000',
      regex: '^4\\d{0,15}',
      cardtype: 'visa'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
      cardtype: 'mastercard'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])\\d{0,16}',
      cardtype: 'elo',
    },
    {
      mask: '0000 000000 00000',
      regex: '^3[47]\\d{0,13}',
      cardtype: 'amex'
    },
    {
      mask: '0000 000000 0000',
      regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
      cardtype: 'diners'
    },
    {
      mask: '0000 0000 0000 0000',
      regex: '^(637095)|(637612)|(637599)|(637609)|(637568)\\d{0,13}',
      cardtype: 'hipercard',
    },
    {
      mask: '0000 0000 0000 0000',
      cardtype: 'unknown'
    }
  ],

  dispatch(appended, dynamicMasked) {
    const number = (dynamicMasked.value + appended).replace(/\D/g, '')
    for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
      const re = new RegExp(dynamicMasked.compiledMasks[i].regex)
      if (number.match(re) != null) {
        return dynamicMasked.compiledMasks[i]
      }
    }
  }
}

export const expirationMask = {
  mask: 'MM{/}YY',
  blocks: {
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12
    },
    YY: {
      mask: IMask.MaskedRange,
      from: 21,
      to: 99
    },
  },
}

export const securityMask = {
  mask: '0000',
}
