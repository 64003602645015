import Amex from './Amex.vue'
import Diners from './Diners.vue'
import Elo from './Elo.vue'
import Hipercard from './Hipercard.vue'
import Mastercard from './Mastercard.vue'
import Visa from './Visa.vue'

export {
    Amex,
    Diners,
    Elo,
    Hipercard,
    Mastercard,
    Visa,
}
