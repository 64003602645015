var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        version: "1.1",
        id: "Layer_1",
        "xmlns:sketch": "http://www.bohemiancoding.com/sketch/ns",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        width: "750",
        height: "471",
        viewBox: "0 0 750 471",
        "enable-background": "new 0 0 750 471",
        "xml:space": "preserve",
      },
    },
    [
      _c("title", [_vm._v("diners")]),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _c("g", { attrs: { id: "diners", "sketch:type": "MSLayerGroup" } }, [
        _c("path", {
          attrs: {
            id: "Shape-path",
            "sketch:type": "MSShapeGroup",
            fill: "#666",
            d: "M584.934,236.947c0-99.416-82.98-168.133-173.896-168.1h-78.241c-92.003-0.033-167.73,68.705-167.73,168.1c0,90.931,75.729,165.641,167.73,165.203h78.241C501.951,402.587,584.934,327.857,584.934,236.947L584.934,236.947z",
          },
        }),
        _c("path", {
          attrs: {
            id: "Shape-path_1_",
            "sketch:type": "MSShapeGroup",
            fill: "#ccc",
            d: "M333.281,82.932c-84.069,0.026-152.193,68.308-152.215,152.58c0.021,84.258,68.145,152.532,152.215,152.559c84.088-0.026,152.229-68.301,152.239-152.559C485.508,151.238,417.369,82.958,333.281,82.932L333.281,82.932z",
          },
        }),
        _c("path", {
          attrs: {
            id: "Path",
            "sketch:type": "MSShapeGroup",
            fill: "#666",
            d: "M237.066,235.098c0.08-41.18,25.747-76.296,61.94-90.25v180.479C262.813,311.381,237.145,276.283,237.066,235.098z M368.066,325.373V144.848c36.208,13.921,61.915,49.057,61.981,90.256C429.981,276.316,404.274,311.426,368.066,325.373z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }