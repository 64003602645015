var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 2337.9 1104.7" },
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 2337.9 1104.7",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("g", [
          _c("g", [
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M481.7,338.4c22.5-7.5,46.5-11.5,71.4-11.5c109,0,200,77.4,220.8,180.3l154.5-31.5\n        c-35.4-174.8-190-306.4-375.3-306.4c-42.5,0-83.2,6.9-121.4,19.7L481.7,338.4z",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#bbb" },
              attrs: {
                d: "M299.4,839l104.5-118.1c-46.6-41.3-76-101.6-76-168.8c0-67.1,29.4-127.4,76-168.6L299.4,265.3\n        C220.2,335.5,170.2,438,170.2,552.1C170.2,666.3,220.2,768.8,299.4,839",
              },
            }),
            _c("path", {
              staticStyle: { fill: "#666" },
              attrs: {
                d: "M773.9,597.3C753,700.1,662.1,777.4,553.2,777.4c-25,0-49.1-4-71.5-11.6l-50,149.6\n        c38.2,12.7,79,19.6,121.5,19.6c185.1,0,339.6-131.4,375.2-306L773.9,597.3z",
              },
            }),
          ]),
        ]),
        _c("path", {
          staticStyle: { fill: "#777" },
          attrs: {
            d: "M1063.5,725.8c-5.1-8.2-11.9-21.3-16.1-31c-24.5-56.8-25.6-115.6-5-172c22.7-61.9,66.1-109.2,122.2-133.4\n    c70.5-30.3,148.4-24.4,216,15.7c42.9,24.6,73.3,62.6,96.4,116.4c2.9,6.9,5.5,14.2,8.1,20.5L1063.5,725.8z M1204.3,480.7\n    c-50.1,21.5-75.9,68.5-70.5,123.5l212-91.3C1309.3,470.1,1261.9,455.9,1204.3,480.7z M1372.2,679.3c-0.1,0-0.1,0.1-0.1,0.1l-4.4-3\n    c-12.7,20.5-32.4,37.2-57.3,48c-47.4,20.6-91.3,15.3-122.9-12.4l-2.9,4.4c0,0,0-0.1-0.1-0.1l-53.8,80.5\n    c13.4,9.3,27.7,17.1,42.7,23.4c59.4,24.7,120.2,23.5,180.1-2.5c43.3-18.8,77.3-47.4,100.5-83.7L1372.2,679.3z",
          },
        }),
        _c("path", {
          staticStyle: { fill: "#777" },
          attrs: {
            d: "M1632.4,271v447.4l69.6,28.2l-39.5,92.2l-76.8-32c-17.2-7.5-29-18.9-37.8-31.8c-8.5-13.1-14.8-31.2-14.8-55.4V271H1632.4z",
          },
        }),
        _c("g", [
          _c("path", {
            staticStyle: { fill: "#777" },
            attrs: {
              d: "M1812.6,604.5c0-38.1,16.9-72.3,43.5-95.5l-71.4-79.6c-48.4,42.8-78.9,105.3-78.9,174.9c-0.1,69.7,30.4,132.3,78.7,175.1\n      l71.3-79.6C1829.3,676.5,1812.6,642.5,1812.6,604.5z",
            },
          }),
          _c("path", {
            staticStyle: { fill: "#777" },
            attrs: {
              d: "M1939.4,731.4c-14,0-27.6-2.4-40.2-6.6l-34.1,101.3c23.3,7.8,48.2,12.1,74.2,12.1c113,0.1,207.3-80.1,229.2-186.6\n      l-104.8-21.4C2051.8,688.1,2000.7,731.5,1939.4,731.4z",
            },
          }),
          _c("path", {
            staticStyle: { fill: "#777" },
            attrs: {
              d: "M1939.7,370.8c-25.9,0-50.8,4.2-74.1,11.9l33.8,101.4c12.7-4.2,26.2-6.5,40.2-6.5c61.4,0.1,112.6,43.7,124.2,101.7\n      l104.8-21.2C2147.1,451.4,2052.8,370.9,1939.7,370.8z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }