export default class Validation {

    static validateCreditCard(creditCardData) {
        return !!(creditCardData.name &&
            creditCardData.name.replace(/\d|\s/g, "").length > 2 &&
            creditCardData.cardNumber &&
            creditCardData.cardNumber.length >= 10 &&
            creditCardData.expiration &&
            creditCardData.expiration.length === 5 &&
            creditCardData.security &&
            creditCardData.security.length >= 3 &&
            creditCardData.company &&
            creditCardData.company.length > 0);

    }

}